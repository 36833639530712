import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class OrderMixin extends Vue {
  order: any = null

  beforeMount() {
    if (!this.$route.params.lookup_ref)
      this.$router.replace({ name: "404", query: this.$defaultQuery() })
    this.getOrder()
  }

  get isLoading() {
    return !this.order
  }

  getOrder() {
    this.$api
      .getOrder(this.$route.params.lookup_ref)
      .then((response: any) => {
        this.order = response
      })
      .catch(() => {
        this.order = {}
      })
  }
}
