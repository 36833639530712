<template>
  <div class="card mt-2">
    <div class="card-content">
      <div>
        <div class="attendee-summary">
          <div>
            <span class="has-text-weight-bold mr-2">
              <translate>Asistente</translate>
              {{ index + 1 }}
            </span>
            <span v-if="ticket.concession">{{ ticket.concession.name }}</span>
            <span v-else>{{ ticket.seat.zone_generic_name }}</span>
          </div>
          <div>
            <span>
              <translate>Zona</translate>
              : {{ ticket.seat.zone_name }}
            </span>
          </div>
        </div>
        <div class="zone-attendee-summary">
          <span v-if="ticket.seat.row && ticket.seat.name">
            {{ ticket.seat.zone_rows_name }}: {{ ticket.seat.row }} -
            <translate>Butaca</translate>
            :
            {{ ticket.seat.name }}
          </span>
          <span v-else>s/n</span>
        </div>
        <div class="columns is-multiline">
          <div class="column is-5">
            <label class="labels">
              <translate>Nombre</translate>
              *
            </label>
            <FieldInput
              name="name"
              :required="true"
              :errors="attendeeErrors.name"
              v-model="formDataParticipant.name"
              type="text"
            />
          </div>
          <div class="column is-7">
            <label class="labels">
              <translate>Apellidos</translate>
              *
            </label>
            <FieldInput
              name="surname"
              :required="true"
              :errors="attendeeErrors.surname"
              v-model="formDataParticipant.surname"
            />
          </div>
        </div>
        <div
          class="columns is-multiline"
          v-for="(item, $index) in order.event.attendee_form_config"
          :key="$index"
        >
          <div
            class="column is-12"
            v-if="item.status == 'mandatory' || item.status == 'optional'"
          >
            <label class="labels">
              {{ item.label }}
              <span v-if="item.status == 'mandatory'">*</span>
            </label>
            <FieldInput
              v-if="item.field != 'province'"
              :name="item.field"
              :required="item.status == 'mandatory'"
              :type="
                item.field == 'phone'
                  ? 'tel'
                  : item.field == 'date_of_birth'
                  ? 'date'
                  : item.field == 'email'
                  ? 'email'
                  : 'text'
              "
              :errors="attendeeErrors[$index]"
              v-model="formDataParticipant[$index]"
            />
            <template v-else>
              <b-select
                :required="item.status == 'mandatory'"
                :errors="attendeeErrors[item.field]"
                :name="item.field"
                v-model="formDataParticipant[$index]"
                class="field"
                :class="{
                  'is-required': item.status == 'mandatory',
                  'is-danger':
                    attendeeErrors[item.field] && attendeeErrors[item.field].length > 0,
                }"
              >
                <option v-for="province in subdivisions" :key="province.id">
                  {{ province.name }}
                </option>
              </b-select>
              <p class="help is-danger" :key="$index">
                {{ attendeeErrors[item.field] }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"

export default {
  name: "OrderParticipantsForm",
  props: ["attendee", "ticket", "index", "errors", "order"],
  data() {
    return {
      subdivisions: {},
      formDataParticipant: {
        ticket_id: this.ticket.id,
        name: "",
        surname: "",
      },
    }
  },
  mounted() {
    this.$api.subdivisions().then((response) => {
      this.subdivisions = response.results.sort((a, b) => {
        return String(a.name).localeCompare(String(b.name))
      })
    })
  },
  computed: {
    hasErrors() {
      return _.size(this.errors)
    },
    attendeeErrors() {
      let errors = {}
      if (_.isString(this.errors)) {
        return errors
      }
      if (this.errors.name) {
        errors.name = this.$gettext("Este campo es obligatorio")
      }
      if (this.errors.surname) {
        errors.surname = this.$gettext("Este campo es obligatorio")
      }
      if (this.errors.city) {
        errors.city = this.$gettext("Este campo es obligatorio")
      }
      if (this.errors.province) {
        errors.province = this.$gettext("Este campo es obligatorio")
      }
      if (this.errors.phone) {
        errors.phone = this.errors.phone
      }
      if (this.errors.identity_card) {
        errors.identity_card = this.errors.identity_card
      }
      if (this.errors.date_of_birth) {
        errors.date_of_birth = this.errors.date_of_birth
      }
      if (this.errors.email) {
        errors.email = this.errors.email
      }
      if (this.errors.postal_code) {
        errors.postal_code = this.errors.postal_code
      }
      if (this.errors.company_name) {
        errors.company_name = this.errors.company_name
      }
      if (this.errors.fiscal_address) {
        errors.fiscal_address = this.errors.fiscal_address
      }
      if (this.errors.fiscal_identification_code) {
        errors.fiscal_identification_code = this.errors.fiscal_identification_code
      }
      if (this.errors.subscriber_number) {
        errors.subscriber_number = this.errors.subscriber_number
      }
      return errors
    },
  },
  methods: {
    emitData() {
      this.$emit("update:attendee", this.formDataParticipant)
    },
  },
  watch: {
    formDataParticipant: {
      handler() {
        this.emitData()
      },
      deep: true,
    },
  },
}
</script>

<style lang="sass">
@import '@/assets/sass/_variables.sass'
.labels
  font-weight: $weight-light

.select:not(.is-multiple)
  width: 100% !important
  select
    width: 100% !important

.attendee-summary
  display: flex
  justify-content: space-between
.zone-attendee-summary
  display: flex
  justify-content: flex-end
</style>
