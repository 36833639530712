<template>
  <div
    class="modal discount-dialog"
    :class="{ 'is-active': displayModalDiscount }"
    scroll="keep"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="modal-card-body">
        <h1 class="title mt-2 has-text-weight-light" v-translate>
          Aplicar código de descuento
        </h1>
        <div class="my-4 is-size-6 has-text-weight-light" v-translate>
          ¿Estás seguro que deseas aplicar este código de descuento? Los descuentos no
          son acumulables, se reemplazará el descuento aplicado.
        </div>
        <div class="my-2 modal-buttons-discount">
          <b-button @click="close" v-translate>
            No deseo aplicar este descuento
          </b-button>
          <b-button type="is-info" @click="confirm" v-translate>
            Sí, quiero aplicarlo igualmente
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscountConfirmDialog",
  data() {
    return {
      displayModalDiscount: false,
    }
  },
  methods: {
    close() {
      this.displayModalDiscount = false
    },
    confirm() {
      this.displayModalDiscount = false
      this.$emit("confirm")
    },
  },
}
</script>

<style lang="sass">
@import '@/assets/sass/_responsive.sass'


.modal.discount-dialog
    +from($tablet)
        .modal-content
            max-width: 600px
        .modal-buttons-discount
            display: flex
            justify-content: space-between

    +until($tablet)
        .modal-content
            max-height: 100vh
            width: 100vw
        .modal-buttons-discount .button
            width: 100%
            margin-bottom: 1rem
</style>
