







































































































































































































import filters from "@/filters"
import _ from "lodash"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import EventDetail from "@/components/EventDetail.vue"
import ConfirmSeatDialog from "@/components/ConfirmSeatDialog.vue"

@Component({
  filters: filters,
  components: { ConfirmSeatDialog, EventDetail },
})
export default class OrderSummary extends Vue {
  visibleDetails: boolean = true
  @Prop({
    type: Object,
    required: true,
    default: {},
  })
  order?: Dictionary<any>

  // SeatNoticeModal
  seatWithNotice: any = null
  noticeZone: any = null

  toggleDetails() {
    this.visibleDetails = !this.visibleDetails
  }

  ticketFillAttr(ticket: any) {
    return _.get(ticket, "seat.color", null)
  }

  get numTickets() {
    return _.filter(
      this.order?.tickets,
      (ticket) => ticket.seat.zone_type != "row_zero"
    ).length
  }

  get hasDiscount() {
    return this.order && this.order.id && this.order.discount
  }

  get hasRowZero() {
    return _.some(
      this.order?.order_lines_summary,
      (line: any) => line.zone_type == "row_zero"
    )
  }

  get hasReducedMobility() {
    return _.some(
      this.order?.tickets,
      (ticket: any) => ticket.seat.representation == "reduced_mobility"
    )
  }

  getTaxTotal(taxDistribution: any) {
    return parseFloat(taxDistribution.tax_amount) + parseFloat(taxDistribution.tax_base)
  }
  getTaxBase(totalAmount: string, taxDistribution: any) {
    return parseFloat(totalAmount) - parseFloat(taxDistribution.tax_amount)
  }

  displaySeatNotice(ticket: any) {
    this.seatWithNotice = ticket.seat
    this.noticeZone = {
      name: ticket.seat.zone_name,
      color: ticket.seat.color,
      rows_name: ticket.seat.zone_rows_name,
    }
  }

  closeNoticeDialog() {
    this.seatWithNotice = null
    this.noticeZone = null
  }
}
