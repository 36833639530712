<template>
  <PageDefault :isLoading="isLoading" :order="order">
    <template v-slot:navbar-start>
      <div class="navbar-item">
        <a class="button is-link has-text-white bg-none" @click="goBack">
          <b-icon pack="fas" icon="arrow-left"></b-icon>
        </a>
      </div>
    </template>
    <template v-slot:navbar-brand>
      <div class="navbar-item">
        <a @click="goBackWeb">
          <img :src="$theme().image" :alt="$theme().name" />
        </a>
      </div>
    </template>

    <template v-slot:navbar-end>
      <Timer
        v-if="!ffTimerV2Enabled && order"
        class="navbar-item"
        style="margin-bottom: 0.25rem"
        :order="order"
      />
      <TimerV2
        v-if="ffTimerV2Enabled && order"
        class="navbar-item"
        style="margin-bottom: 0.25rem"
        :order="order"
      />
    </template>
    <template v-slot:content>
      <AbandonOrderDialog
        v-if="order"
        ref="abandonOrderDialog"
        :order="order"
        :goToWeb="goToWeb"
        @hideConfirmAbandon="displayConditionsDialog"
      />
      <ConditionsDialog
        ref="conditionsDialog"
        :order="order"
        @abandon="conditionsDialogAbandon"
        @agree="agreeConditions"
      />
      <div class="annotation">
        <translate>
          Dispones de un tiempo limitado para realizar tu compra. Tras este tiempo se
          liberará tu reserva automáticamente.
        </translate>
      </div>
      <OrderSummary class="order-summary" :order="order" />
      <div class="discount-codes" v-if="order.has_applicable_discount_coupons">
        <ApplyDiscountCodeForm
          v-on:update-order="getOrder()"
          :order="order"
        ></ApplyDiscountCodeForm>
      </div>
      <ContactDataForm
        class="contact-data-form mt-4"
        :order="order"
        :errors="errors"
        :data.sync="formData"
        @displayConditions="displayConditionsDialog"
        ref="contactDataForm"
      />
      <PaymentForm ref="paymentForm" :order="order" />
      <PaymentInfo class="payment-info mx-1" v-if="requiresPayment" />
      <section class="block mx-1 mb-3">
        <button
          @click="confirmOrder"
          data-cy="button-confirm-order"
          class="button button-2406 fullwidth-mobile is-info"
          type="submit"
          v-if="requiresPayment"
          :disabled="isConfirmingOrder"
        >
          {{ payButtonText }}
        </button>
        <button
          @click="confirmAndCompleteOrder"
          data-cy="button-confirm-order"
          class="button button-2406 fullwidth-mobile is-info"
          type="submit"
          v-if="!requiresPayment"
          :disabled="isConfirmingFreeOrder"
        >
          {{ confirmButtonText }}
        </button>
        <div style="height: 75px"></div>
      </section>
    </template>
  </PageDefault>
</template>

<script>
import OrderMixin from "@/mixins/OrderMixin"

import AbandonOrderDialog from "@/components/dialogs/AbandonOrderDialog"
import Timer from "@/components/Timer"
import TimerV2 from "@/components/TimerV2"

import ApplyDiscountCodeForm from "@/components/sections/ApplyDiscountCodeForm"
import ContactDataForm from "@/components/sections/ContactDataForm"
import OrderSummary from "@/components/sections/OrderSummary"
import PaymentInfo from "@/components/sections/PaymentInfo"
import PaymentForm from "@/components/sections/PaymentForm"
import ConditionsDialog from "@/components/dialogs/ConditionsDialog.vue"

export default {
  name: "OrderConfirm",
  components: {
    AbandonOrderDialog,
    ApplyDiscountCodeForm,
    ConditionsDialog,
    Timer,
    TimerV2,
    ContactDataForm,
    OrderSummary,
    PaymentInfo,
    PaymentForm,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.requiresPayment && to.name == "order-success") {
      return next()
    }
    if (to.name == "order-expired") {
      return next()
    }
    if (this.$refs.abandonOrderDialog && !this.$refs.abandonOrderDialog.abandoned) {
      this.$refs.abandonOrderDialog.confirmAbandon()
      return next(false)
    }
    return next()
  },
  computed: {
    requiresPayment() {
      if (!this.order || !this.order.id) {
        return true
      }
      return this.order.requires_payment
    },
    payButtonText() {
      return this.$gettext("Pagar ahora")
    },
    confirmButtonText() {
      return this.$gettext("Confirmar compra")
    },
    ffTimerV2Enabled() {
      return this.$env && this.$env.ff_timer_v2 === true
    },
  },
  mixins: [OrderMixin],
  data() {
    return {
      errors: {},
      formData: {},
      isConfirmingOrder: false,
      isConfirmingFreeOrder: false,
      goToWeb: false,
      gtmNotified: false,
    }
  },

  methods: {
    getOrder() {
      this.$api
        .getOrder(this.$route.params.lookup_ref)
        .then((response) => {
          this.order = response

          if (!this.gtmNotified) {
            this.$store.dispatch("gtm2404/addShippingInfo", { order: this.order })
            this.gtmNotified = true
          }
        })
        .catch(() => {
          this.order = {}
        })
    },
    conditionsDialogAbandon() {
      this.$refs.abandonOrderDialog.confirmAbandon()
    },
    displayConditionsDialog() {
      this.$refs.conditionsDialog.showModal = true
    },
    goBack() {
      this.$refs.abandonOrderDialog.confirmAbandon()
    },
    goBackWeb() {
      this.goToWeb = true
      this.$refs.abandonOrderDialog.confirmAbandon()
    },
    agreeConditions() {
      // Set conditions checkbox as given and send that value
      this.$refs.contactDataForm.orderSpecificConditionsStatus = "given"
    },
    confirmAndCompleteOrder: function (e) {
      this.isConfirmingFreeOrder = true
      e.preventDefault()
      this.$api
        .confirmAndCompleteFreeOrder(this.order.lookup_ref, this.formData)
        .then((response) => {
          this.pushGTMDataConfirmOrder()
          this.$router.push({
            name: "order-success",
            params: { lookup_ref: response.lookup_ref },
            query: this.$defaultQuery(),
          })
        })
        .catch((error) => {
          this.isConfirmingFreeOrder = false
          if (error.response.status == 400) {
            this.errors = error.response.data.details
            this.$store.dispatch(
              "ui/error",
              this.$gettext("El formulario contiene errores")
            )
          }
        })
    },
    confirmOrder: function (e) {
      e.preventDefault()
      this.isConfirmingOrder = true
      this.$api
        .confirmOrder(this.order.lookup_ref, this.formData)
        .then((response) => {
          this.pushGTMDataConfirmOrder()
          this.$refs.paymentForm.submit(response)
        })
        .catch((error) => {
          this.isConfirmingOrder = false
          if (error.response.status == 400) {
            this.errors = error.response.data.details
            this.$store.dispatch(
              "ui/error",
              this.$gettext("El formulario contiene errores")
            )
          }
        })
    },
    pushGTMDataConfirmOrder: function () {
      this.$store.dispatch("gtm/step4", this.order)
      this.$store.dispatch("gtm2404/addPaymentInfo", { order: this.order })
    },
  },
  watch: {
    "order.status"(value) {
      if (value == "finished") {
        this.$router.push({ name: "order-success", query: this.$defaultQuery() })
      } else if (value == "abandoned") {
        this.$router.push({ name: "order-expired", query: this.$defaultQuery() })
      }
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.annotation
  background: rgba($info, .2)
  color: darken($info, 20%)
  font-weight: 500
  text-align: center
  margin: 1rem auto 1.5rem auto
  @include from($tablet)
    padding: 1.5rem .75rem
  @include until($tablet)
    padding: .75rem

.event-card
  flex-grow: 1

.payment-info
  background: $grey-lighter
  margin: 1.5rem auto

.order-confirm-grid
  margin-top: 2rem
  @include until($widescreen)
    .contact-data-form
      margin-top: 1.5rem
  @include from($widescreen)
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    & > *
      width: 50%

    .contact-data-form
      order: 0
      width: 100%
    .order-summary
      width: 100%
      order: 1
      font-size: .9rem
    .payment-info
      order: 3
    .order-confirm-actions
      order: 4
</style>
