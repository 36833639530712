<template>
  <section class="contact-data-form mt-2">
    <h4 v-translate>Utiliza un código de descuento</h4>
    <form ref="contactDataForm" autocomplete="on">
      <p v-translate class="has-errors-msg" v-if="hasErrors">
        Por favor, corrije los siguientes errores.
      </p>
      <div class="columns is-multiline">
        <div class="column is-6">
          <form v-on:submit.prevent="checkModal">
            <div class="field has-addons">
              <div class="control">
                <FieldInput
                  type="text"
                  :errors="errors.discount_code"
                  v-model="discountCode"
                  :required="true"
                  :placeholder="strings['promo']"
                />
              </div>
              <div class="control ml-2">
                <button type="submit" class="button btnapply is-primary">
                  <translate>Aplicar</translate>
                </button>
              </div>
              <DiscountConfirmDialog
                ref="discountConfirmDialog"
                @confirm="applyDiscountCode"
              />
            </div>
          </form>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
import _ from "lodash"
import DiscountConfirmDialog from "@/components/dialogs/DiscountConfirmDialog"

export default {
  name: "ApplyDiscountCodeForm",
  props: ["order"],
  components: {
    DiscountConfirmDialog,
  },
  data() {
    return {
      discountCode: "",
      errors: {},
    }
  },
  computed: {
    hasErrors() {
      return _.size(this.errors)
    },
    strings() {
      return {
        promo: this.$gettext("Código"),
      }
    },
  },
  methods: {
    checkModal() {
      if (
        this.order.discount &&
        (!this.order.discount_coupon ||
          this.order.discount_coupon.code != this.discountCode)
      ) {
        this.$refs.discountConfirmDialog.displayModalDiscount = true
      } else {
        this.applyDiscountCode()
      }
    },
    applyDiscountCode() {
      const data = { discount_code: this.discountCode }
      this.$api.applyDiscountCode(this.order.lookup_ref, data).then(
        () => {
          this.discountCode = ""
          this.errors = {}
          const msg = this.$gettext("Código promocional aplicado.")
          this.$store.dispatch("ui/success", msg)
          this.$emit("update-order")
        },
        (error) => {
          if (error.response.status == 400) {
            this.errors = error.response.data.details
            this.$store.dispatch("ui/error", error.response.data.message)
          }
        }
      )
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
p.has-errors-msg
  color: $danger
  background: rgba($danger, 0.1)
  font-size: .875rem
  padding: 1em .75em

.btnapply
  min-height: 0
</style>
